import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import "./styles/content.css";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";

const ReactMarkdown = require("react-markdown/with-html");
class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isMobile: true };
  }

  render() {
    var width = "100%";
    var alignment = "left";
    if (this.props.data.settings.alignment === "left") {
      alignment = "left";
    }
    if (this.props.data.settings.alignment === "right") {
      alignment = "right";
    }
    if (this.props.data.settings.alignment === "center") {
      alignment = "center";
    }
    if (this.props.data.settings.alignment === "justify") {
      alignment = "justify";
    }
    if (this.props.data.settings.width === "large") {
      width = "1280px";
    }
    if (this.props.data.settings.width === "medium") {
      width = "1024px";
    }
    if (this.props.data.settings.width === "small") {
      width = "640px";
    }

    if (window.innerWidth > 640) {
      this.setState({
        isMobile: false
      });
    }

    if (window.innerWidth <= 640) {
      this.setState({
        isMobile: true
      });
    }

    const reportWindowSize = () => {
      if (window.innerWidth > 640) {
        this.setState({
          isMobile: false
        });
      }

      if (window.innerWidth <= 640) {
        this.setState({
          isMobile: true
        });
      }
    };

    window.addEventListener("resize", reportWindowSize);

    return (
      <div
        className="contentContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          {!this.props.data.settings.raw ? (
            <div
              className="contentInner"
              style={{
                maxWidth: width,
                width: "100%",
                display: "flex",
                alignItems: "center",

                minHeight:
                  this.state.isMobile && this.props.data.settings.mobile_height
                    ? this.props.data.settings.mobile_height
                    : !this.state.isMobile && this.props.data.settings.height
                    ? this.props.data.settings.height
                    : "75vh"
              }}
            >
              <span
                className="contentPara"
                style={{
                  color: this.props.data.settings.text_color
                    ? this.props.data.settings.text_color
                    : this.props.textColor,
                  textAlign: alignment
                }}
              >
                <ReactMarkdown
                  source={this.props.data.settings.content}
                  escapeHtml={false}
                />
              </span>
            </div>
          ) : (
            <Fade>
              <div>
                <ReactMarkdown
                  source={this.props.data.settings.content}
                  escapeHtml={false}
                />
              </div>
            </Fade>
          )}
        </CockpitSettingsWrapper>
      </div>
    );
  }
}
export default Content;

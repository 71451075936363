import React, { PureComponent } from "react";
import "./styles/footer.css";

import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import PrivacyPopUp from "../privacyPopUp/PrivacyPopUp";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Helmet } from "react-helmet";
const ReactMarkdown = require("react-markdown/with-html");

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true
    };
  }

  async fetchFooter() {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}singletons/get/footer`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": process.env.REACT_APP_TOKEN
        },
        body: JSON.stringify({
          lang: this.props.locale,
          populate: 6
          // fields: { name: 1, url_handle: 1 }
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      footer: jsonData
    });
  }

  async componentDidMount() {
    this.setState({
      fetching: true
    });
    await this.fetchFooter();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.setState({
        fetching: true
      });

      await this.fetchFooter();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    return (
      <div>
        <div
          className="footer"
          style={
            !this.state.fetching
              ? {
                  backgroundColor: this.state.footer.bg_color,
                  color: this.state.footer.text_color
                }
              : null
          }
        >
          {!this.state.fetching ? (
            <div className="footerInner">
              {this.state.footer.logo ? (
                <>
                  <Link
                    className="footerlogolink"
                    to={!this.props.locale ? `/` : `/${this.props.locale}/`}
                  >
                    <img
                      className="footerLogo"
                      src={`${process.env.REACT_APP_STORAGE}${
                        this.state.footer.logo.path
                      }`}
                      alt="logo"
                      style={{
                        height: "6.2rem",
                        display: "inline-block",
                        width: "auto",
                        maxWidth: "100%"
                      }}
                    />
                  </Link>
                </>
              ) : null}

              <ReactMarkdown
                source={this.state.footer.content}
                escapeHtml={false}
              />

              <p className="socialfooter">
                <SocialIcon
                  bgColor="rgba(255,255,255,1)"
                  url={
                    "https://www.facebook.com/MV-Motorsport-Sagl-824245910981222"
                  }
                />
              </p>
              <div className="footerNav">
                {this.state.footer.nav
                  ? this.state.footer.nav.map((item1, i1) => {
                      return item1.field.name === "parent" ? (
                        <List className="footerList" key={i1}>
                          <ListItem>
                            <ListItemText>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item1.value.label
                                }}
                              />
                            </ListItemText>
                          </ListItem>

                          {item1.value.links.map((item2, i2) => {
                            return item2.field.name === "page" ? (
                              <ListItem
                                button
                                key={i2}
                                component={Link}
                                to={
                                  !this.props.locale
                                    ? `/${item1.value.handle}/${
                                        item2.value.link.url_handle
                                      }`
                                    : `/${this.props.locale}/${
                                        item1.value.handle
                                      }/${item2.value.link.url_handle}`
                                }
                                style={{
                                  color: this.props.textColor
                                }}
                              >
                                <ListItemText>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item2.value.label
                                    }}
                                  />
                                </ListItemText>
                              </ListItem>
                            ) : item2.field.name === "custom" ? (
                              item2.value.url.indexOf("/") === 0 ? (
                                <ListItem
                                  button
                                  key={i2}
                                  component={Link}
                                  to={
                                    !this.props.locale
                                      ? `${item2.value.url}`
                                      : `/${this.props.locale}${
                                          item2.value.url
                                        }`
                                  }
                                  style={{
                                    color: this.props.textColor
                                  }}
                                >
                                  <ListItemText>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item2.value.label
                                      }}
                                    />
                                  </ListItemText>
                                </ListItem>
                              ) : (
                                <ListItem
                                  button
                                  key={i2}
                                  component="a"
                                  target={item2.value.new_tab ? "_blank" : ""}
                                  href={item2.value.url}
                                  style={{
                                    color: this.props.textColor
                                  }}
                                >
                                  <ListItemText>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item2.value.label
                                      }}
                                    />
                                  </ListItemText>
                                </ListItem>
                              )
                            ) : null;
                          })}
                        </List>
                      ) : item1.field.name === "page" ? (
                        <List className="footerList" key={i1}>
                          <ListItem
                            button
                            component={Link}
                            to={
                              !this.props.locale
                                ? `/${item1.value.link.url_handle}`
                                : `/${this.props.locale}/${
                                    item1.value.link.url_handle
                                  }`
                            }
                            style={{
                              color: this.props.textColor
                            }}
                          >
                            <ListItemText>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item1.value.label
                                }}
                              />
                            </ListItemText>
                          </ListItem>
                        </List>
                      ) : item1.field.name === "custom" ? (
                        <List className="footerList" key={i1}>
                          {item1.value.url.indexOf("/") === 0 ? (
                            <ListItem
                              button
                              key={i1}
                              component={Link}
                              to={
                                !this.props.locale
                                  ? `${item1.value.url}`
                                  : `/${this.props.locale}${item1.value.url}`
                              }
                              style={{
                                color: this.props.textColor
                              }}
                            >
                              <ListItemText>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item1.value.label
                                  }}
                                />
                              </ListItemText>
                            </ListItem>
                          ) : (
                            <ListItem
                              button
                              key={i1}
                              component="a"
                              target={item1.value.new_tab ? "_blank" : ""}
                              href={item1.value.url}
                              style={{
                                color: this.props.textColor
                              }}
                            >
                              <ListItemText>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item1.value.label
                                  }}
                                />
                              </ListItemText>
                            </ListItem>
                          )}
                        </List>
                      ) : null;
                    })
                  : null}
              </div>
            </div>
          ) : null}
        </div>
        {!this.state.fetching ? (
          <PrivacyPopUp locale={this.props.locale} />
        ) : null}
      </div>
    );
  }
}

export default Footer;

import React, { PureComponent } from "react";
import Swiper from "react-id-swiper";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import "./styles/idGallery.css";
import CloseIcon from "@material-ui/icons/Close";
class IdGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false
    };
  }

  componentDidMount() {
    console.log(this.props.data);
    if (
      document.getElementById(this.props.data.settings.id) &&
      document.getElementById(`gallery${this.props.data.settings.id}`)
    ) {
      document.getElementById(this.props.data.settings.id).onclick = e => {
        e.preventDefault();
        document
          .getElementById(`gallery${this.props.data.settings.id}`)
          .classList.add("open");
        document.body.style.overflow = "hidden";
        var resizeEvent = new Event("resize");

        window.dispatchEvent(resizeEvent);
      };
    }
  }

  closeidGallery = () => {
    document
      .getElementById(`gallery${this.props.data.settings.id}`)
      .classList.remove("open");
    document.body.style.overflow = "";
  };

  render() {
    var width = "1024px";

    if (this.props.data.settings.width === "full") {
      width = "100%";
    }

    if (this.props.data.settings.width === "large") {
      width = "1280px";
    }
    if (this.props.data.settings.width === "medium") {
      width = "1024px";
    }
    if (this.props.data.settings.width === "small") {
      width = "640px";
    }
    const params = {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next.swiper-button-white",
        prevEl: ".swiper-button-prev.swiper-button-white"
      },
      spaceBetween: 0

      //slidesPerView: 1,
      // loop: false,
      // autoplay: {
      //   delay: 6000
      // }
      //effect: "fade"
    };

    return (
      <div
        className="idGalleryContainer"
        id={`gallery${this.props.data.settings.id}`}
      >
        <button id="closeIdGallery" onClick={() => this.closeidGallery()}>
          <CloseIcon />
        </button>
        <CockpitSettingsWrapper data={this.props.data}>
          <div
            className="idGallery"
            style={{
              width: "100%",
              maxWidth: "1240px"
            }}
          >
            <Swiper {...params}>
              {this.props.data.settings.items.map((item, i) => {
                return (
                  <img
                    src={`${
                      process.env.REACT_APP_API_ENDPOINT
                    }cockpit/image?token=${process.env.REACT_APP_TOKEN}&src=${
                      item.value._id
                    }&w=1024&h=640&q=50&o=true`}
                    alt={item.value.title}
                  />
                );
              })}
            </Swiper>
          </div>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default IdGallery;

import React, { PureComponent } from "react";

import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import "./styles/gridGallery.css";

class GridGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false
    };
  }

  toggleLightbox = src => {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
      src: src
    });
    setTimeout(function() {
      document.body.style.overflow = "hidden";
    }, 500);
  };

  closeLightbox = src => {
    this.setState({
      lightboxIsOpen: false
    });
    document.body.style.overflow = "";
  };

  render() {
    var width = "1024px";

    if (this.props.data.settings.width === "full") {
      width = "100%";
    }

    if (this.props.data.settings.width === "large") {
      width = "1280px";
    }
    if (this.props.data.settings.width === "medium") {
      width = "1024px";
    }
    if (this.props.data.settings.width === "small") {
      width = "640px";
    }

    return (
      <div
        className="gridListContainer"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <div
            className="gridList"
            style={{
              maxWidth: width,
              margin: "0 auto"
            }}
          >
            {this.props.data.settings.items.map((item, i) => {
              return (
                <div
                  key={i}
                  className="gridListItem"
                  onClick={() =>
                    this.toggleLightbox(
                      `${process.env.REACT_APP_STORAGE}${item.value.path}`
                    )
                  }
                >
                  <img
                    src={`${
                      process.env.REACT_APP_API_ENDPOINT
                    }cockpit/image?token=${process.env.REACT_APP_TOKEN}&src=${
                      item.value._id
                    }&w=640&h=640&q=50&o=true`}
                    alt={item.value.title}
                  />
                </div>
              );
            })}
          </div>

          {this.state.lightboxIsOpen ? (
            <div
              className="lightBox"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: "100vh",
                width: "100vw",
                zIndex: "10000",
                display: "flex",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.8)",
                backgroundImage: `url(${this.state.src})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center"
              }}
            >
              <div
                className="buttonCloseFull"
                onClick={() => this.closeLightbox()}
              />
            </div>
          ) : null}
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default GridGallery;

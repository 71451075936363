import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import "./styles/imageAndText.css";
import { Link } from "react-router-dom";

const ReactMarkdown = require("react-markdown/with-html");

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="imageAndTextContainer"
        style={{
          color: this.props.textColor,
          backgroundColor: this.props.bgColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <div
            className={
              this.props.data.settings.reverse
                ? "imageAndText "
                : "imageAndText reverse"
            }
            style={{
              backgroundImage: !this.props.data.settings.reverse
                ? `url(${require("../assets/flaginv.svg")})`
                : `url(${require("../assets/flag.svg")})`,
              backgroundRepeat: "no-repeat"
            }}
          >
            <div
              className={
                this.props.data.settings.reverse
                  ? "imageAndTextElem thegrid "
                  : "imageAndTextElem thegrid reverse"
              }
            >
              <div className="image">
                <ConditionalWrapper
                  condition={
                    this.props.data.settings.image_url &&
                    this.props.data.settings.image_url.indexOf("/") === 0
                  }
                  wrapper={children => (
                    <Link
                      to={
                        !this.props.locale
                          ? this.props.data.settings.image_url
                          : `/${this.props.locale}${
                              this.props.data.settings.image_url
                            }`
                      }
                      title={this.props.data.settings.image.title}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <ConditionalWrapper
                    condition={
                      this.props.data.settings.image_url &&
                      this.props.data.settings.image_url.indexOf("/") !== 0
                    }
                    wrapper={children => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.data.settings.image_url}
                        title={this.props.data.settings.image.title}
                      >
                        {children}
                      </a>
                    )}
                  >
                    {this.props.data.settings.reverse ? (
                      <div className="wow bounceInUp">
                        <img
                          src={`${process.env.REACT_APP_STORAGE}${
                            this.props.data.settings.image.path
                          }`}
                          alt={this.props.data.settings.image.title}
                          style={{
                            marginTop: this.props.data.settings.image_offset
                              ? "-4rem"
                              : 0
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_STORAGE}${
                          this.props.data.settings.image.path
                        }`}
                        alt={this.props.data.settings.image.title}
                        style={{
                          marginTop: this.props.data.settings.image_offset
                            ? "-4rem"
                            : 0
                        }}
                      />
                    )}
                  </ConditionalWrapper>
                </ConditionalWrapper>
              </div>
              {this.props.data.settings.reverse ? (
                <img
                  className="imageAndTextLeft"
                  src={require("../assets/textleft.svg")}
                />
              ) : (
                <img
                  className="imageAndTextRight"
                  src={require("../assets/textright.svg")}
                />
              )}

              <div style={{ color: this.props.textColor }} className="text">
                <div
                  className="textContainer"
                  style={{ color: this.props.textColor }}
                >
                  <div className="textInner">
                    <ReactMarkdown
                      source={this.props.data.settings.text}
                      escapeHtml={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default Content;
